import request from '@/utils/request'

//首页数据
export const  getNodeList  = () => request.get('node/nodeList')

export const  myList  = () => request.get('node/myList')

//增加
export const  addSign  = (data) => request.get('node/add',data)




