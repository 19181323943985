<template>
	<div class="my-container p-all-20">
		<div class="top-box d-space-column">
			<!-- 头部背景 -->
			<div class="top-border-bg max-bg"></div>
			<!-- 侧边 -->
			<div class="side-border p-all-10"
				:class="[lang === 'en-US' ? 'long' : lang === 'zh-CN' ? 'short' : lang === 'kr' ? 'kr' : '']">
			</div>
			<!-- 介绍 -->
			<div class="arc-info max-bg p-all">
				<div class="title-box d-center-row">
					<img src="../../assets/image/title-icon-1.png" alt="">
					<span class="m-left-right f-w">LELE</span>
					<img src="../../assets/image/title-icon-2.png" alt="">
				</div>
				<div class="content f-22 d-center-column">
					<div class="m-top-bottom">
						<span> {{ i18n.title }}</span>
					</div>
					<div class="m-bottom">
						<span>{{ i18n.content }}</span>
					</div>
				</div>
			</div>
			<!-- 价格 -->
			<div class="price-box max-bg d-center-row">
				<span class="f-36 f-w">{{ i18n.price }}：</span>
				<span class="f-40 f-w col-main">$ {{ params.price }}</span>
			</div>
			<!-- 统计 -->
			<!-- <div class="stat-box max-bg d-around-row m-bottom">
				<div class="d-space-column">
					<span class="f-38 col-main">{{ params.produce }}</span>
					<span>{{ i18n.production }}</span>
				</div>
				<div class="d-space-column">
					<span class="f-38 col-main">{{ params.destroy }}</span>
					<span>{{ i18n.destroy }}</span>
				</div>
			</div> -->
			<div class="bottom-border max-bg"></div>
		</div>

		<div class="sign-box max-bg d-space-row">
			<div class="d-space-column align-start">
				<span class="s-value" v-if="signParams.leleAmount"> {{signParams.leleAmount}} <span class="coin">LELE</span> </span>
				<div class="d-space-row justify-start">
					<img src="../../assets/image/sign-icon.png" alt="" />
					<span class="sign-label">{{ i18n.signReward }}</span>
				</div>
			</div>
			<div class="sign-btn max-bg d-center-row" v-if="!signParams.signed" @click = "nowSign">
				<span>{{ i18n.signNow }}</span>
			</div>
			<div class="sign-btn signed max-bg d-center-row" v-else>
				<span>{{ i18n.signed }}</span>
			</div>
		</div>

		<div class="bottom-box d-space-column">
			<!-- 底部背景 -->
			<div class="bottom-border-bg max-bg"></div>
			<!-- 侧边 -->
			<div class="bottom-side-border p-all-10"></div>
			<!-- 组合支付 -->
			<div class="my-node-box d-space-row p-all">
				<span class="f-42">{{ i18n.pay }}</span>
				<div class="node-btn max-bg d-center-row" @click="$router.push({ name: 'myNode' })">
					<span>{{ i18n.myNode }}</span>
				</div>
			</div>
			<!-- 节点列表 -->
			<div class="node-list">
				<div class="node-item max-bg" v-for="(item, index) in nodeList">
					<div class="node-info d-space-row justify-start">
						<span class="f-36 m-right" v-if="item.id == 1">{{ i18n.node1 }}</span>
						<span class="f-36 m-right" v-if="item.id == 2">{{ i18n.node2 }}</span>
						<span class="f-36 m-right" v-if="item.id == 3">{{ i18n.node3 }}</span>
						<span class="f-36 m-right" v-if="item.id == 4">{{ i18n.node4 }}</span>

						<span
							class="f-26 col-main m-right-10">{{ parseFloat(item.arcRate / 100 * item.amount / params.price).toFixed(2) }}</span>
						<span class="f-22 col-main">LELE</span>
						<span class="f-30 f-w col-main m-left-right-10">+</span>
						<span class="f-32 col-main m-right-10">{{ item.usdtRate / 100 * item.amount }}</span>
						<span class="f-22 col-main">USDT</span>
					</div>
					<div class="buy-btn max-bg d-center-row" @click="nowGet(item.id)">
						<span class="f-36">{{ i18n.buyNow }}</span>
					</div>
				</div>
			</div>
			<div class="bottom-border max-bg"></div>
		</div>
	</div>
</template>

<script>
	import {
		addSign
	} from "@/api/node.js";

	import {
		getNodeList
	} from "@/api/node.js";
	import {
		indexParams
	} from "@/api/index.js";
	import {
		bindParent,sign,signInfo
	} from "@/api/account.js";
	import {
		mapGetters
	} from 'vuex'
	export default {
		data() {
			return {
				signParams:{
					signed:true,
					leleAmount:""
				},
				params: {

				},
				nodeList: []
			};
		},
		computed: {
			...mapGetters(['lang']),
			i18n() {
				return this.$t('index')
			}
		},
		created() {
			this.$dapp.useAddress(() => {
				this.$nextTick(() => {
					this.getParams()
					this.getSignInfo()
					this.getNodeList()
					let params = this.$route.query;
					let inviteCode = params.inviteCode;
					if (inviteCode != undefined) {
						this.bind(inviteCode)
					}
				})
			})
		},
		methods: {
			async nowGet(nodeId) {

				await this.$dapp.onConnect();
				const {
					err,
					res
				} = await addSign({
					nodeId: nodeId
				});
				if (err == null) {

					const nodeShop = await this.$dapp.nodeShop();
					const token = await this.$dapp.token();
					const usdt = await this.$dapp.usdt();
					await token.approve(nodeShop.address);
					await usdt.approve(nodeShop.address);
					const [error, tx] = await nodeShop.buyNode(res);
					if (error === null) {
						this.$toast(this.$t("app.opeSuccess"));
					}
				}
			},
			async getParams() {
				const {
					err,
					res
				} = await indexParams();
				if (err === null) {
					this.params = res;
				}
			},
			async getSignInfo(){
				const {
					err,
					res
				} = await signInfo();
				if (err === null) {
					this.signParams = res;
				}
			},
			async bind(inviteCode) {
				await bindParent({
					inviteCode
				})
			},
			async nowSign(){
				const {
					err,
					res
				} = await sign();
				if (err === null) {
					this.getSignInfo();
				}
			},
			async getNodeList() {
				const {
					err,
					res
				} = await getNodeList();
				if (err === null) {
					this.nodeList = res;
				}
			}
		}

	}
</script>

<style lang="scss" scoped>
	.my-container {
		.signed{
			filter: grayscale(1);
		}
		.top-box {
			width: 100%;
			// height: auto;
			position: relative;
			box-sizing: border-box;
			padding-top: 50px;

			.top-border-bg {
				width: 100%;
				height: 300px;
				background: url('../../assets/image/border-bg.png');
				position: absolute;
				left: 0;
				top: 0;
			}

			.side-border {
				width: 100%;
				border-left: 2px solid #4BB005;
				border-right: 2px solid #4BB005;
				position: absolute;
				left: 0;
				bottom: 0;

				&.long {
					height: 600px;
				}

				&.short {
					height: 520px;
				}

				&.kr {
					height: 580px;
				}
			}

			.arc-info {
				width: 643px;
				// height: 586px;
				background: url('../../assets/image/info-bg.png');
				position: relative;

				.title-box {
					width: 643px;
					height: 50px;
					// border: 1px solid red;
					position: absolute;
					top: -26px;
					left: 0;

					img {
						width: 54px;
						height: 30px;
					}

					font-size: 42px;
				}
			}

			.price-box {
				width: 643px;
				height: 154px;
				background: url('../../assets/image/price-bg.png');
				margin: 64px 0;
			}

			.stat-box {
				width: 643px;
				height: 201px;
				background: url('../../assets/image/stat-bg.png');
			}
		}

		.sign-box {
			width: 100%;
			height: 223px;
			background: url('../../assets/image/sign-bg.png');
			margin: 40px 0;
			box-sizing: border-box;
			padding: 0 100px;

			.s-value {
				font-size: 48px;
				color: #AFFE78;

				.coin {
					font-size: 24px;
					color: #AFFE78;
				}
			}

			img {
				width: 34px;
				height: 32px;
				margin-right: 10px;
			}

			.sign-label {
				color: #DEFFC6;
			}

			.sign-btn {
				width: 178px;
				height: 63px;
				background: url('../../assets/image/sign-btn-bg.png');
				color: #193406;
			}
		}

		.bottom-box {
			width: 100%;
			position: relative;
			// margin-top: 77px;

			.bottom-border-bg {
				width: 100%;
				height: 280px;
				background: url('../../assets/image/bottom-border-bg.png');
				position: absolute;
				top: 0;
				left: 0;
			}

			.bottom-side-border {
				width: 100%;
				// height: 1102px;
				border-left: 2px solid #4BB005;
				border-right: 2px solid #4BB005;
				position: absolute;
				left: 0;
				bottom: 0;
			}

			.my-node-box {
				width: 90%;
				margin-bottom: 60px;
				position: relative;
				z-index: 9;

				.node-btn {
					width: 181px;
					height: 57px;
					background: url('../../assets/image/my-node-bg.png');
				}
			}

			.node-list {
				z-index: 8;
				width: 644px;

				.node-item {
					width: 100%;
					height: 239px;
					background: url('../../assets/image/node-bg.png');
					box-sizing: border-box;
					margin-bottom: 54px;

					.node-info {
						width: 100%;
						height: 87px;
						background: rgba(75, 176, 5, 0.3);
						box-sizing: border-box;
						padding: 20px 45px;
						align-items: baseline;
					}

					.buy-btn {

						width: 518px;
						height: 71px;
						background: url('../../assets/image/btn-bg.png');
						margin: 0 auto;
						margin-top: 35px;
					}
				}
			}
		}

		.bottom-border {
			width: 100%;
			height: 2px;
			background: url('../../assets/image/bottom-border.png');
		}
	}
</style>